<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container">
        <form class="content register-content">
          <div class="nav-responsive">
            <ul class="nav nav-register">
              <template v-for="(item, index) in itemsStep">
                <li
                  :key="index"
                  :class="(item.active && 'active') || (item.finish && 'navtop-canclick')"
                  @click="item.finish && stepwh_signup < 8 && changeStep(item.linkItems)"
                >
                  <a href="" class="d-flex flex-column">
                    <span v-if="item.finish" class="icons"
                      ><img src="@/assets/icons/icon-checkmark.svg" alt=""
                    /></span>
                    <span class="text">{{ item.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <div class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto">
            <div class="form-box">
              <div class="form-group">
                <h3 class="title-md mb-2">{{ $t("SignupStep7.title") }}</h3>
                <label class="light f20 mb-0">
                  {{ $t("SignupStep7.label") }}
                </label>
              </div>
            </div>
            <div class="form-box">
              <div
                class="btn btn-outline hover-pink commission mt-0 d-flex align-center justify-center"
              >
                <div class="group fontStyle">
                  {{ $t("SignupStep7.commission1") }}
                  <!-- <small class="mb-2">{{ $t("SignupStep7.label_commission1") }}</small> -->
                </div>
              </div>
              <div
                class="btn btn-outline hover-pink commission d-flex align-center justify-center"
              >
                <div class="group pt-2 fontStyle">
                  {{ $t("SignupStep7.commission2") }}
                  <small class="mb-2">{{ $t("SignupStep7.label_commission2") }}</small>
                </div>
              </div>
              <div class="form-group mt-4 pt-2">
                <label style="color: #ee3068; font-weight: 500">
                  {{ $t("SignupStep7.title_profit") }}
                </label>
                <ul class="list-pink">
                  <li v-for="item in vatData.profits.items" :key="item">
                    {{ item }}
                  </li>
                </ul>
              </div>
              <div class="form-group mt-4 pt-2">
                <label style="color: #ee3068; font-weight: 500">
                  {{ $t("SignupStep7.condition_title") }}
                </label>
                <ul class="list-pink">
                  <li v-for="item in vatData.conditions.items" :key="item">
                    {{ item }}
                  </li>
                </ul>
              </div>
              <div class="d-flex flex-row align-center justify-end">
                <ul class="list-pink">
                  {{
                    $t("SignupStep7.condition_warn")
                  }}
                </ul>
              </div>
            </div>
            <div class="form-box">
              <div class="form-group">
                <h3 class="title-md mb-2">
                  {{ $t("SignupStep7.payment_condition_title") }}
                </h3>
                <label class="light">{{ $t("SignupStep7.payment_condition_des") }}</label>
              </div>
              <div class="form-group">
                <label class="light">
                  {{ $t("SignupStep7.payment_condition_name") }}
                  <!-- <span class="d-block f14">{{
                    $t("SignupStep7.label_pay_commission3")
                  }}</span> -->
                </label>
                <input
                  type="text"
                  class="form-control"
                  name=""
                  v-model="vatData.tax.paidname"
                />
              </div>
            </div>
            <div class="form-box">
              <div class="form-group">
                <label class="d-block light">{{
                  $t("SignupStep7.title_same_address")
                }}</label>
                <div class="row">
                  <div class="col-md-6">
                    <div class="button-answer nav address">
                      <div
                        @click="sameAddressFunc()"
                        class="btn btn-outline hover-pink"
                        :class="vatData.tax.sameaddress == true && 'btn-outline-active'"
                      >
                        <span>{{ $t("SignupStep7.btn_yes") }}</span>
                      </div>
                      <div
                        @click="clearSameAddress()"
                        class="btn btn-outline hover-pink no"
                        :class="vatData.tax.sameaddress == false && 'btn-outline-active'"
                      >
                        <span>{{ $t("SignupStep7.btn_no") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-address" v-if="vatData.tax.sameaddress == false">
                  <div class="form-group">
                    <label class="light">{{ $t("SignupStep7.location1") }}</label>
                    <input
                      v-model="vatData.tax.address1"
                      type="text"
                      class="form-control"
                      name=""
                      :placeholder="$t('SignupStep7.place_location1')"
                    />
                  </div>
                  <div class="form-group">
                    <label class="light">{{ $t("SignupStep7.location2") }}</label>
                    <input
                      v-model="vatData.tax.address2"
                      type="text"
                      class="form-control"
                      name=""
                      :placeholder="$t('SignupStep7.place_location2')"
                    />
                  </div>
                  <div class="form-group">
                    <label class="light">{{ $t("SignupStep7.location7") }}</label>
                    <div class="form-control-2" id="country" @click="getfocus('country')">
                      <a data-toggle="dropdown" data-display="static" id="country">
                        <v-autocomplete
                          v-model="vatData.tax.address7"
                          :items="itemsCountry"
                          item-text="name"
                          item-value="value"
                          label=""
                          placeholder=""
                          :no-data-text="$t('SignupStep.nodata')"
                          color="pink"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                          autocomplete="off"
                          @mouseenter="getfocus('country')"
                          @mouseleave="mouseOver('country')"
                        ></v-autocomplete>
                      </a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="light">{{ $t("SignupStep7.location5") }}</label>
                    <div
                      class="form-control-2"
                      id="province"
                      @click="getfocus('province')"
                    >
                      <!-- <v-select
                        v-model="vatData.tax.address5"
                        :items="itemsProvince"
                        item-text="province"
                        item-value="province_code"
                        :disabled="!vatData.tax.address7"
                        :no-data-text="$t('SignupStep.nodata')"
                        solo
                        flat
                        dense
                        hide-details
                        @change="selectProvinceFunc()"
                      ></v-select> -->
                      <a data-toggle="dropdown" data-display="static" id="province">
                        <v-autocomplete
                          v-model="vatData.tax.address5"
                          :items="itemsProvince"
                          item-text="province"
                          item-value="province_code"
                          label=""
                          placeholder=""
                          :no-data-text="$t('SignupStep.nodata')"
                          color="pink"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                          autocomplete="off"
                          @mouseenter="getfocus('province')"
                          @mouseleave="mouseOver('province')"
                          @change="selectProvinceFunc()"
                        ></v-autocomplete>
                      </a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="light">{{ $t("SignupStep7.location3") }}</label>
                    <div class="form-control-2" id="amphoe" @click="getfocus('amphoe')">
                      <!-- <v-select
                        v-model="vatData.tax.address3"
                        :items="itemsAmphoe"
                        :disabled="!vatData.tax.address5"
                        item-text="amphoe"
                        item-value="amphoe_code"
                        :no-data-text="$t('SignupStep.nodata')"
                        solo
                        flat
                        dense
                        hide-details
                        @change="selectAmphoeFunc()"
                      ></v-select> -->
                      <a data-toggle="dropdown" data-display="static" id="amphoe">
                        <v-autocomplete
                          v-model="vatData.tax.address3"
                          :items="itemsAmphoe"
                          item-text="amphoe"
                          item-value="amphoe_code"
                          label=""
                          placeholder=""
                          :no-data-text="$t('SignupStep.nodata')"
                          color="pink"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                          autocomplete="off"
                          @mouseenter="getfocus('amphoe')"
                          @mouseleave="mouseOver('amphoe')"
                          @change="selectAmphoeFunc()"
                        ></v-autocomplete>
                      </a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="light">{{ $t("SignupStep7.location4") }}</label>
                    <div
                      class="form-control-2"
                      id="district"
                      @click="getfocus('district')"
                    >
                      <!-- <v-select
                        v-model="vatData.tax.address4"
                        :items="itemsDistrict"
                        item-text="district"
                        item-value="district_code"
                        :disabled="!vatData.tax.address3"
                        :no-data-text="$t('SignupStep.nodata')"
                        solo
                        flat
                        dense
                        hide-details
                        @change="selectLocation6Func()"
                      ></v-select> -->
                      <a data-toggle="dropdown" data-display="static" id="district">
                        <v-autocomplete
                          v-model="vatData.tax.address4"
                          :items="itemsDistrict"
                          item-text="district"
                          item-value="district_code"
                          label=""
                          placeholder=""
                          :no-data-text="$t('SignupStep.nodata')"
                          color="pink"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                          autocomplete="off"
                          @mouseenter="getfocus('district')"
                          @mouseleave="mouseOver('district')"
                          @change="selectLocation6Func()"
                        ></v-autocomplete>
                      </a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="light">{{ $t("SignupStep7.location6") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      name=""
                      placeholder=""
                      v-model="vatData.tax.address6"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-box">
              <h3 class="title-md">{{ $t("SignupStep7.title_finish1") }}</h3>
              <div class="form-group">
                <label class="f18">{{ $t("SignupStep7.label_finish1") }}</label>
                <label class="light">
                  {{ $t("SignupStep7.label_finish2") }}
                </label>
              </div>
              <hr />
              <h3 class="title-md pt-3">
                {{ $t("SignupStep7.title_check1") }}
                <!-- <span class="nowrap"> -->
                {{ $t("SignupStep7.title_check2") }}
                <!-- </span> -->
              </h3>
              <div class="checkbox-group d-block">
                <input id="agree1" v-model="vatData.cb1" name="agree1" type="checkbox" />
                <label for="agree1" class="multiline">
                  <span>{{ $t("SignupStep7.label_check1") }}</span>
                </label>
              </div>
              <div class="checkbox-group d-block mt-4">
                <input id="agree2" v-model="vatData.cb2" name="agree2" type="checkbox" />
                <label for="agree2" class="multiline">
                  <span>
                    {{ $t("SignupStep7.label_check2_1") }}
                    <a @click="gotogeneralterms()">{{
                      $t("SignupStep7.label_check2_2")
                    }}</a>
                    {{ $t("SignupStep7.label_check2_3") }}
                    <a @click="gotoserviceterms()">{{
                      $t("SignupStep7.label_check2_4")
                    }}</a>
                    {{ $t("SignupStep7.label_check2_5") }}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="form-buttons">
            <div
              class="pa-0 d-flex flex-row align-center justify-space-between flex-100per"
            >
              <button
                type="button"
                class="btn-back gl-bt hover_btn ml-0"
                style="text-transform: capitalize !important"
                @click="goback()"
              >
                <span>{{ `< ${$t("servicepage.back")}` }}</span>
              </button>

              <button
                type="button"
                class="btn xs gl-bt hover_btn mx-0"
                style="text-transform: capitalize !important"
                :disabled="!cangonextstep"
                @click="checkForSignUp(8)"
              >
                <span>{{ $t("SignupStep7.btn_next") }}</span>
              </button>
            </div>
          </div>
        </form>
        <div class="sidebar">
          <div class="inner d-flex">
            <a
              :href="pathrightphoto"
              class="img-exemple iphone-model d-flex flex-column align-center justify-center"
              data-fancybox
            >
              <span class="btn-open-picture">{{
                $t("SignupStep4.clickopenpicture")
              }}</span>
              <img :src="pathrightphoto" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import raw from "@/constants/raw.json";
import swal from "sweetalert";

export default {
  name: "PaymentScreen",
  metaInfo: {
    title: "Payment",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    vatData: {
      profits: {
        title: "สิทธิประโยชน์จากการร่วมงานกับ AIGENT",
        items: [
          "ไม่มีค่าธรรมเนียมแอบแฝง มีเพียงค่าคอมมิชชั่นในอัตราเดียวกับที่ระบุไว้เท่านั้น",
          "จ่ายค่าคอมมิชชั่นเฉพาะการจองที่มีผู้พักเท่านั้น",
          "มีบริการให้ความช่วยเหลือทุกวันตลอด 24ชม. ทั้งทางโทรศัพท์และทางอีเมล",
          "เพิ่มยอดจองจากการปรากฏที่โดดเด่นในผลการค้นหาบนเสิร์ชเอนจิน",
          "ให้คำแนะนำแกที่พักและข้อมูลวิเคราะห์เพื่อเพิ่มประสิทธิภาพในการดำเนินธุรกิจ",
          "ช่วยให้ท่านประหยัดเวลาด้วยระบบยืนยันการจองทันที",
        ],
      },
      conditions: {
        title: "",
        items: [],
      },
      tax: {
        paidname: "",
        sameaddress: null,
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        address5: "",
        address6: "",
        address7: "",
      },
      cb1: false,
      cb2: false,
      reserve: "ลงทะเบียนเสร็จสิ้นและเปิดรับการจองทันที",
      reserves: [
        "ลงทะเบียนเสร็จสิ้นและเปิดรับการจองทันที",
        "ลงทะเบียนเสร็จสิ้นและเปิดรับรับการจองภายหลัง",
      ],
    },
    itemsCountry: [
      {
        _id: 0,
        name: "ไทย",
        value: "th",
      },
    ],
    rawData: raw,
    itemsProvince: [],
    itemsAmphoe: [],
    itemsDistrict: [],
    propertyData: {},
    propertyID: "",
    value_element: "",
    stepwh_signup: 7,
    itemsStep: [],
    pathrightphoto: "",
    cangonextstep: false,
    userToken: "",
    dataFromBasic: null,
    allsixstepvalid: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.rateplanID = self.$route.params.rateplanId;
    if (self.userToken && self.propertyID) {
      self.initStepData();
    } else {
      self.gotosignup();
    }
    if (
      (localStorage.getItem("Token") == "" || !localStorage.getItem("Token")) &&
      self.userToken
    ) {
      localStorage.setItem("Token", self.userToken);
    }
    localStorage.setItem("signupstep", self.stepwh_signup);
    localStorage.setItem("SignupStep", self.stepwh_signup);
    (self.vatData.reserve = self.$t("SignupStep7.select_reserved")),
      (self.vatData.reserves = [
        self.$t("SignupStep7.item_reserve1"),
        self.$t("SignupStep7.item_reserve2"),
      ]);
  },
  watch: {
    "vatData.tax.sameaddress"() {
      const self = this;
    },
    "vatData.tax.address5"() {
      const self = this;
      self.selectProvinceFunc();
    },
    "vatData.tax.address3"() {
      const self = this;
      self.selectAmphoeFunc();
    },
    "vatData.tax.address4"() {
      const self = this;
      self.selectLocation6Func();
    },
    "vatData.tax"() {
      const self = this;
      self.checkcangonext();
    },
    "vatData.cb1"() {
      const self = this;
      self.checkcangonext();
    },
    "vatData.cb2"() {
      const self = this;
      self.checkcangonext();
    },
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initStepData);
    this.colorHover();
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("focus-2");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus-2");
        element.classList.add("focus-2");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus-2");
        element.classList.remove("focus-2");
        this.value_element = "";
      }
    },
    gotosignin() {
      var item = { link: "signin", name: "SigninScreen" };
      EventBus.$emit("changePathname", item);
    },
    gotosignup() {
      var item = { link: "signup", name: "SignupScreen" };
      EventBus.$emit("changePathname", item);
    },
    gotogeneralterms() {
      window.open(`${window.location.origin || process.env.VUE_APP_WEB}/signup/general`);
    },
    gotoserviceterms() {
      window.open(`${window.location.origin || process.env.VUE_APP_WEB}/signup/privacy`);
    },
    checkcangonext() {
      const self = this;
      // console.log(self.vatData)
      if (
        self.vatData.tax.paidname &&
        self.vatData.tax.address1 &&
        // && self.vatData.tax.address2
        self.vatData.tax.address3 &&
        self.vatData.tax.address4 &&
        self.vatData.tax.address5 &&
        self.vatData.tax.address6 &&
        self.vatData.tax.address7 &&
        self.vatData.cb1 &&
        self.vatData.cb2 &&
        self.allsixstepvalid
      ) {
        self.cangonextstep = true;
      } else self.cangonextstep = false;
    },
    checkValueCountry() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const tempCountry = self?.itemsCountry?.find(
        (p) => p.name == self.vatData.tax.address7
      );
      if (tempCountry?.value) {
        self.vatData.tax.address7 = tempCountry?.value || "";
      }
      const tempProvince = self?.itemsProvince?.find(
        (p) =>
          p.province == self.vatData.tax.address5 &&
          p.amphoe == self.vatData.tax.address3 &&
          p.district == self.vatData.tax.address4
      );
      if (
        tempProvince?.province_code &&
        tempProvince?.amphoe_code &&
        tempProvince?.district_code
      ) {
        self.vatData.tax.address5 = tempProvince?.province_code || "";
        self.vatData.tax.address3 = tempProvince?.amphoe_code || "";
        self.vatData.tax.address4 = tempProvince?.district_code || "";
      }
      EventBus.$emit("endloading");
    },
    async initStepData() {
      const self = this;
      var temp = {};
      var tempPolicy = {};
      var tempInvoice = {};
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          // console.log(res.data.result[0])
          tempPolicy = res?.data?.result[0];
          tempInvoice = res?.data?.result[0]?.invoice_contact;
          res?.data?.result?.map((el) => {
            if (el._id == self.propertyID) temp = el;
          });
          self.propertyData = tempPolicy;
          self.dataFromBasic = tempPolicy?.contact || null;
          if (tempInvoice?.name) {
            self.vatData.tax.paidname = tempInvoice?.name || "";
            self.vatData.tax.address1 = tempInvoice?.address_line_1 || "";
            self.vatData.tax.address2 = tempInvoice?.address_line_2 || "";
            self.vatData.tax.address3 = tempInvoice?.district || "";
            self.vatData.tax.address4 = tempInvoice?.subdistrict || "";
            self.vatData.tax.address5 = tempInvoice?.province || "";
            self.vatData.tax.address6 = tempInvoice?.post_code || "";
            self.vatData.tax.address7 = tempInvoice?.country || "";
            if (
              tempInvoice?.address_line_1 == tempPolicy?.contact?.address_line_1 &&
              tempInvoice?.address_line_2 == tempPolicy?.contact?.address_line_2 &&
              tempInvoice?.district == tempPolicy?.contact?.district &&
              tempInvoice?.subdistrict == tempPolicy?.contact?.subdistrict &&
              tempInvoice?.province == tempPolicy?.contact?.province &&
              tempInvoice?.post_code == tempPolicy?.contact?.post_code &&
              tempInvoice?.country == tempPolicy?.contact?.country
            ) {
              self.vatData.tax.sameaddress = true;
            } else self.vatData.tax.sameaddress = false;
          } else {
            self.vatData.tax.sameaddress = true;
            self.vatData.tax.paidname = tempPolicy?.contact?.name || "";
            self.vatData.tax.address1 = tempPolicy?.contact?.address_line_1 || "";
            self.vatData.tax.address2 = tempPolicy?.contact?.address_line_2 || "";
            self.vatData.tax.address3 = tempPolicy?.contact?.district || "";
            self.vatData.tax.address4 = tempPolicy?.contact?.subdistrict || "";
            self.vatData.tax.address5 = tempPolicy?.contact?.province || "";
            self.vatData.tax.address6 = tempPolicy?.contact?.post_code || "";
            self.vatData.tax.address7 = tempPolicy?.contact?.country || "";
          }
          self.vatData.cb1 = false;
          self.vatData.cb2 = false;
          self.initDataStep = temp;
          self.keepUserID = res?.data?.result[0]?.user?._id;
          if (res?.data?.result?.length) {
            self.addPolicyData = {
              amountday: tempPolicy?.book_in_advance || 0,
              protect: tempPolicy?.book_in_unintentional || false,
              checkin: tempPolicy?.check_in || "",
              checkout: tempPolicy?.check_out || "",
              allowchild: tempPolicy?.child || false,
              allowpet: tempPolicy?.pet || false,
            };
            if (
              temp?.step_signup?.step1 &&
              temp?.step_signup?.step2 &&
              temp?.step_signup?.step3 &&
              temp?.step_signup?.step4 &&
              temp?.step_signup?.step5 &&
              temp?.step_signup?.step6
            ) {
              self.allsixstepvalid = true;
            } else self.allsixstepvalid = false;
            self.itemsStep = [
              {
                name: self.$t("SignupStep.step1"),
                active: (self.stepwh_signup == 1 && true) || false,
                finish: temp?.step_signup?.step1 || false,
                linkItems: {
                  link: `signup/basic/${self.userToken}/${self.propertyID}`,
                  name: "SignupBasicInformationScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 1,
              },
              {
                name: self.$t("SignupStep.step2"),
                active: (self.stepwh_signup == 2 && true) || false,
                finish: temp?.step_signup?.step2 || false,
                linkItems: {
                  link: `signup/room/${self.userToken}/${self.propertyID}`,
                  name: "SignupRoomScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 2,
              },
              {
                name: self.$t("SignupStep.step3"),
                active: (self.stepwh_signup == 3 && true) || false,
                finish: temp?.step_signup?.step3 || false,
                linkItems: {
                  link: `signup/rateplan/${self.userToken}/${self.propertyID}`,
                  name: "SignupRatePlanScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 3,
              },
              {
                name: self.$t("SignupStep.step4"),
                active: (self.stepwh_signup == 4 && true) || false,
                finish: temp?.step_signup?.step4 || false,
                linkItems: {
                  link: `signup/facilities/${self.userToken}/${self.propertyID}`,
                  name: "SignupFacilitiesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 4,
              },
              {
                name: self.$t("SignupStep.step5"),
                active: (self.stepwh_signup == 5 && true) || false,
                finish: temp?.step_signup?.step5 || false,
                linkItems: {
                  link: `signup/pictures/${self.userToken}/${self.propertyID}`,
                  name: "SignupPicturesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 5,
              },
              {
                name: self.$t("SignupStep.step6"),
                active: (self.stepwh_signup == 6 && true) || false,
                finish: temp?.step_signup?.step6 || false,
                linkItems: {
                  link: `signup/policy/${self.userToken}/${self.propertyID}`,
                  name: "SignupPolicyScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 6,
              },
              {
                name: self.$t("SignupStep.step7"),
                active: (self.stepwh_signup == 7 && true) || false,
                finish: temp?.step_signup?.step7 || false,
                linkItems: {
                  link: `signup/payment/${self.userToken}/${self.propertyID}`,
                  name: "SignupPaymentScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 7,
              },
            ];
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        self.gotosignup();
      } finally {
        self.getStepData(self.itemsStep);
        self.vatData.profits = {
          title: self.$t("SignupStep7.title_profit"),
          items: [
            self.$t("SignupStep7.profit_item1"),
            self.$t("SignupStep7.profit_item2"),
          ],
        };
        self.vatData.conditions = {
          title: self.$t("SignupStep7.condition_title"),
          items: [self.$t("SignupStep7.condition_item1")],
        };
      }
    },
    getStepData(items) {
      const self = this;
      self.itemsStep = items;
      self.changePhotoRight();
      self.initCountry();
    },
    changePhotoRight() {
      const self = this;
      var tempLG = localStorage.getItem("language") === "th" ? "th" : "en";
      self.pathrightphoto = `${process.env.VUE_APP_PIC_API}/src/step0-${tempLG}.gif`;
    },
    async initCountry() {
      const self = this;
      EventBus.$emit("loadingtillend");
      var tempCountry = [];
      var temp = [];
      self.loadingCountry = true;
      self.loadingProvince = true;
      self.itemsCountry = [];
      self.itemsProvince = [];
      self.itemsAmphoe = [];
      self.itemsDistrict = [];
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + `/setting/country`, {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          EventBus.$emit("endloading");
          if (res?.data?.result?.length) {
            res?.data?.result?.map((el) => {
              tempCountry.push({
                _id: el._id || 0,
                name: el.value || "ไทย",
                value: el._id || "th",
                province: el.province || [],
              });
              if (el?.province?.length) {
                el?.province?.map((ele) => {
                  temp.push({
                    district: ele?.subdistrict || "",
                    amphoe: ele?.district || "",
                    province: ele?.province || "",
                    zipcode: ele?.zipcode || "",
                    district_code: ele?.subdistrict_code || "",
                    amphoe_code: ele?.district_code || "",
                    province_code: ele?.province_code || "",
                  });
                });
              }
            });
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.loadingCountry = false;
        self.loadingProvince = false;
        self.itemsCountry = tempCountry;
        self.itemsProvince = temp;
        self.checkValueCountry();
      }
    },
    selectProvinceFunc() {
      const self = this;
      var temp = [];
      self?.itemsProvince?.map((el) => {
        if (
          el?.province_code === self?.vatData?.tax?.address5 &&
          self?.vatData?.tax?.address5 &&
          self?.vatData?.tax?.address5 !== undefined
        ) {
          temp.push(el);
        }
      });
      // console.log(temp)
      self.itemsAmphoe = temp;
    },
    selectAmphoeFunc() {
      const self = this;
      var temp = [];
      self?.itemsAmphoe?.map((el) => {
        if (
          el.province_code === self?.vatData?.tax?.address5 &&
          el.amphoe_code === self?.vatData?.tax?.address3 &&
          self?.vatData?.tax?.address5 &&
          self?.vatData?.tax?.address3
        ) {
          temp.push(el);
        }
      });
      // console.log(temp)
      self.itemsDistrict = temp;
    },
    selectLocation6Func() {
      const self = this;
      var temp = {};
      self?.itemsProvince?.map((el) => {
        if (
          el.province_code === self?.vatData?.tax?.address5 &&
          el.amphoe_code === self?.vatData?.tax?.address3 &&
          el.district_code === self?.vatData?.tax?.address4 &&
          self?.vatData?.tax?.address5 &&
          self?.vatData?.tax?.address3 &&
          self?.vatData?.tax?.address4
        ) {
          temp = el;
        }
      });
      if (temp.zipcode) self.vatData.tax.address6 = temp.zipcode;
    },
    sameAddressFunc() {
      const self = this;
      self.vatData.tax.sameaddress = true;
      self.vatData.tax.paidname = self?.dataFromBasic?.name || "";
      self.vatData.tax.address1 = self?.dataFromBasic?.address_line_1 || "";
      self.vatData.tax.address2 = self?.dataFromBasic?.address_line_2 || "";
      self.vatData.tax.address3 = self?.dataFromBasic?.district || "";
      self.vatData.tax.address4 = self?.dataFromBasic?.subdistrict || "";
      self.vatData.tax.address5 = self?.dataFromBasic?.province || "";
      self.vatData.tax.address6 = self?.dataFromBasic?.post_code || "";
      self.vatData.tax.address7 = self?.dataFromBasic?.country || "";
      self.checkValueCountry();
    },
    clearSameAddress() {
      const self = this;
      self.vatData.tax.sameaddress = false;
      self.vatData.tax.address1 = "";
      self.vatData.tax.address2 = "";
      self.vatData.tax.address3 = "";
      self.vatData.tax.address4 = "";
      self.vatData.tax.address5 = "";
      self.vatData.tax.address6 = "";
      self.vatData.tax.address7 = "";
      self.vatData.cb1 = false;
      self.vatData.cb2 = false;
    },
    checkForSignUp(num) {
      const self = this;
      self.checkValueCountry();
      const tempProvince = self.itemsProvince.find(
        (p) =>
          p.province == self.vatData.tax.address5 &&
          p.amphoe == self.vatData.tax.address3 &&
          p.district == self.vatData.tax.address4
      );
      const obj = {
        name: self.vatData.tax.paidname,
        address_line_1: self.vatData.tax.address1,
        address_line_2: self.vatData.tax.address2,
        subdistrict: tempProvince?.district_code || self?.vatData?.tax?.address4,
        district: tempProvince?.amphoe_code || self?.vatData?.tax?.address3,
        province: tempProvince?.province_code || self?.vatData?.tax?.address5,
        post_code: self.vatData.tax.address6,
        country: self.vatData.tax.address7,
        check1: self.vatData.cb1,
        check2: self.vatData.cb2,
        sameplace: self.vatData.tax.sameaddress,
      };

      if (!obj.name || !obj.check1 || !obj.check2) {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else {
        self.updateStep(obj, num);
      }
    },
    async updateStep(item, num) {
      const self = this;
      const obj = {
        invoice_contact: item,
        active: false,
        voucher_status: false,
        voucher_suspend: false,
      };
      // console.log('obj: ', obj)
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/step7?property_id=${self.propertyID}&next=${1}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          setTimeout(() => {
            EventBus.$emit("endloading");
            self.nextstep(num);
          }, 2000);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    nextstep(num) {
      const self = this;
      var linkItems = {
        link: `signup/register/${self.propertyID}`,
        name: "SignupRegisterScreen",
        params: { propertyId: self.propertyID },
      };
      self.stepwh_signup = num;
      EventBus.$emit("loadingPage");
      EventBus.$emit("changeStep", num);
      EventBus.$emit("lastStep");
      self.$store.dispatch("changeSignupStep", {
        signupstep: self.stepwh_signup,
      });
      EventBus.$emit("changePathname", linkItems);
    },
    async changeStep(linkItems) {
      const self = this;
      self.checkValueCountry();
      const tempProvince = self.itemsProvince.find(
        (p) =>
          p.province == self.vatData.tax.address5 &&
          p.amphoe == self.vatData.tax.address3 &&
          p.district == self.vatData.tax.address4
      );
      const item = {
        name: self.vatData.tax.paidname,
        address_line_1: self.vatData.tax.address1,
        address_line_2: self.vatData.tax.address2,
        subdistrict: tempProvince?.district_code || self?.vatData?.tax?.address4,
        district: tempProvince?.amphoe_code || self?.vatData?.tax?.address3,
        province: tempProvince?.province_code || self?.vatData?.tax?.address5,
        post_code: self.vatData.tax.address6,
        country: self.vatData.tax.address7,
        check1: self.vatData.cb1,
        check2: self.vatData.cb2,
        sameplace: self.vatData.tax.sameaddress,
      };
      const obj = {
        invoice_contact: item,
        active: false,
        voucher_status: false,
        voucher_suspend: false,
      };
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/step7?property_id=${self.propertyID}&next=${0}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("changePathname", linkItems);
          EventBus.$emit("endloading");
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
  },
};
</script>

<style>
.btn-outline:hover span {
  color: #fff !important;
}

.btn-outline-active {
  background-color: var(--pink) !important;
  color: #fff !important;
}
.btn-outline-active span {
  color: #fff !important;
}

.btn.gl-bt-next {
  overflow: hidden;
  border: 0;
}

.btn.gl-bt-next:hover {
  background-color: #652b80;
}

.btn.gl-bt-next::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt-next:hover:before {
  --size: 350px;
}

.form-control-2 {
  -webkit-appearance: none;
  color: #000000;
  font-weight: inherit;
  font-size: inherit;
  height: 48px;
  line-height: 48px;
  border-radius: 5px;
  border: 1px solid var(--border-gray);
  background-color: #ffffff;
  font-family: inherit;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.focus-2 {
  background-color: #fff;
  border-color: var(--pink);
  outline: 0;
  box-shadow: none !important;
}

.pink {
  color: #ee3068 !important;
}
.medium {
  font-weight: 500 !important;
}

.btn.btn-outline {
  color: var(--pink) !important;
}

.btn.btn-outline.hover-pink:hover,
.btn.btn-outline.hover-pink.active {
  background-color: var(--pink) !important;
  border-color: var(--pink);
  color: #fff !important;
}

.btn.commission {
  font-weight: 600;
  font-size: 40px;
  width: 100%;
  border: 2px solid var(--pink);
  border-radius: 15px;
  height: 93px;
  margin: 5px 0;
}
.btn.commission .group {
  display: inline-block;
}

.btn.commission small {
  display: block;
  text-align: right;
  font-size: 14px;
  font-weight: 300;
  line-height: 0.6;
}

.fontStyle {
  font-size: 40px;
}

.navtop-canclick {
  cursor: pointer !important;
}

.btn[disabled] {
  height: fit-content !important;
}

@media (max-width: 1680px) {
  .btn.commission {
    height: 85px;
    font-size: 32px;
  }
}

@media (max-width: 1440px) {
  .btn.commission {
    height: 75px;
    font-size: 28px;
  }
  .fontStyle {
    font-size: 28px;
  }
  .select.dropdown > a,
  .bootstrap-select.dropdown > .dropdown-toggle {
    height: 42px;
    line-height: 42px;
  }
}

@media (max-width: 1024px) {
  .btn.commission {
    height: 65px;
    font-size: 22px;
    max-width: 350px;
    padding-top: 0;
  }
  .fontStyle {
    font-size: 22px;
  }
  .btn.commission small {
    font-size: 12px;
    line-height: 0.6;
    position: relative;
    top: -2px;
    padding-bottom: 4px;
  }
  .select.dropdown > a,
  .bootstrap-select.dropdown > .dropdown-toggle {
    height: 38px;
    line-height: 38px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .btn.commission {
    height: 55px;
    font-size: 18px;
    max-width: 300px;
    border-radius: 10px;
  }
  .fontStyle {
    font-size: 18px;
  }
}

.btn.gl-bt-next {
  overflow: hidden;
  border: 0;
}

.btn.gl-bt-next:hover {
  background-color: #652b80;
}
.btn.gl-bt-next::before {
  --size: 0;
  content: "";
  position: absolute;
  left: var(--x);
  top: var(--y);
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt-next:hover:before {
  --size: 350px;
}

.bg-pink .bootstrap-select.dropdown > .dropdown-toggle {
  color: #fff;
  background-color: var(--pink);
  border-color: var(--pink);
  text-align: center;
}

.select.dropdown > a,
.bootstrap-select.dropdown > .dropdown-toggle {
  display: flex;
  position: relative;
  z-index: 1001;
  padding: 10px 15px;
  height: 48px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid var(--border-gray);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.5);
  -ms-flex-align: center;
  align-items: center;
  transition: none !important;
}

.form-box .form-control,
.select.dropdown > a,
.bootstrap-select.dropdown > .dropdown-toggle {
  font-size: var(--f-14);
}
</style>
